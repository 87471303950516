.custom-color(@color-background-banner, @color-background-content: mix(@color-background-banner, #fff, 35%), @color-background-nav: @color-background-content, @color-text-banner: contrast(@color-background-banner, @color-dark), @color-text-heading: @color-background-banner, @color-text-content: @color-medium, @color-text-heading-2: desaturate(@color-text-heading, 15%), @color-text-heading-3: desaturate(@color-text-heading-2, 15%), @color-background: @color-background) {
	#topBodyCurves {
		@media (min-width: @screen-sm-min) {
			@grad: to bottom, @color-background-banner, @color-background-banner 2.6 * @font-size-large - 4px, softlight(@color-background-banner, rgba(0,0,0,0.175));
			background-image: -webkit-linear-gradient(@grad);
			background-image: -moz-linear-gradient(@grad);
			background-image: -o-linear-gradient(@grad);
			background-image: linear-gradient(@grad);
			background-color: @color-background-content;
		    color: @color-text-content;
		}

	   h4 {
	    	color: @color-text-content;
	    }
	}

	.topBodyB {
		.row > div.active > h2 {
			background-color: @color-background-content;
		}

		h2 {
	    	@lightened: lighten(@color-background-banner, 5%);
	    	background-color: @lightened;
	    	.best-contrast(@lightened);
	    	color: @best-contrast;
	    	.link-color(@best-contrast);
	    }
	}

	/*.topBodyTR .row > div + div {
		@media (min-width: @screen-sm-min) {
	    	border-left: 1px solid @color-background-banner;
	    }
	}*/

	#navColumn,
	.topBodyTR,
	#footerWrap {
		background-color: @color-background-content;
	}

	#navColumn .nav-menu li.sublist>a:before {
		color: @color-background-nav;
	}

	@media (min-width: @screen-sm-min) {
		.topBodyTR,
		#navColumn {
			background-color: transparent;
		}

		#navColumn .nav-menu>li {
			background: linear-gradient(to right, @color-background-nav 0%, @color-background 65%);
			border-color: @color-background-nav;
		}

		.nav-menu .current + ul,
		.nav-menu .visible > ul {
			background: linear-gradient(to right, lighten(@color-background-nav, 6%) 0%, @color-background 65%);
			margin-top: 5px;
			margin-bottom: -15px;
			padding-bottom: 15px;
		}

		#footerWrap {
			background-color: @color-background-highlight;
		}

		.sm-card>h2:first-child {
			background-color: @color-text-heading-2;
			.best-contrast(@color-text-heading-2);
			color: @best-contrast;
		}

		.sm-card>h3:first-child {
			background-color: @color-text-heading-3;
			.best-contrast(@color-text-heading-3);
			color: @best-contrast;
		}
	}

	h1, .h1 {
		color: @color-text-heading;
		.link-color(@color-text-heading, 54%);
	}

	h2, .h2 {
		color: @color-text-heading-2;
		.link-color(@color-text-heading-2, 54%);
	}

	h3, .h3 {
		color: @color-text-heading-3;
		.link-color(@color-text-heading-3, 54%);
	}

	.card,
	.list-group {
		>h2:first-child {
			background-color: @color-text-heading-2;
			.best-contrast(@color-text-heading-2);
			color: @best-contrast;
			.link-color(@best-contrast, 54%);
		}

		>h3:first-child {
			background-color: @color-text-heading-3;
			.best-contrast(@color-text-heading-3);
			color: @best-contrast;
			.link-color(@best-contrast, 54%);
		}
	}

	.topBodyTR,
	#footer,
	#navColumn {
		h3 {
			background-color: @color-background-banner;
			color: @color-text-banner;
			.link-color(@color-text-banner);
		}
	}

	::selection {
		background: @color-background-content;
	}
}

#header{
	position: relative;
}

#header:after {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    padding: 2px 5px;
    color: #000;
    text-shadow:
       -1px -1px 0 #fff,  
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
         1px 1px 0 #fff;
}

// http://webaim.org/resources/contrastchecker/
// Headings need a contrast ratio of 4.5:1
// Body text needs a contrast ratio of 7:1

.morning {
	.custom-color(#e7df8b, @color-text-heading: #0078ae);

	#header {
		@media (min-width: @screen-sm-min) {
			background-image: url(/img/banner/new/GettyImages-820759256.jpg);
		}
		background-image: url(/img/banner/new/GettyImages-820759256_mobile.jpg);
		background-size: cover;
		background-attachment: scroll;
		background-position: center left;
	}
}

.afternoon {
	.custom-color(#b2dee7);

	#header {
		@media (min-width: @screen-sm-min) {
			background-image: url(/img/banner/full/camdendelaware.jpg);
		}
		background-image: url(/img/banner/full/camdendelaware_mobile.jpg);
		background-size: cover;
		background-attachment: scroll;
		background-position: center;
	}
}

.evening {
	.custom-color(#dfad92);

	#header {
		@media (min-width: @screen-sm-min) {
			background-image: url(/img/banner/new/GettyImages-909297366.jpg);
		}
		background-image: url(/img/banner/new/GettyImages-909297366_mobile.jpg);
		background-size: cover;
		background-attachment: scroll;
		background-position: center;
	}
}

.night {
	.custom-color(#88B3B3);

	#header {
		@media (min-width: @screen-sm-min) {
			background-image: url(/img/banner/new/GettyImages-529802605.jpg);
		}
		background-image: url(/img/banner/new/GettyImages-529802605_mobile.jpg);
		background-size: cover;
		background-attachment: scroll;background-position: bottom left;
	}
}

.Data_and_Products {
	.custom-color(#003255);

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate3-01.jpg);
	}
}

.Long_Range_Plan_and_TIP {
	.custom-color(#DB653D, @color-text-heading: #1e3c64);

	#header {
		background-image: url(/img/banner/full/southstbridge.jpg);
    }
}

.Transportation_Improvement_Program__TIP_ {
	.custom-color(rgb(24,63,45));
}

.Pennsylvania_TIP {
	.custom-color(#ffdd93, @color-text-heading: #1e3c64);

	#header {
		background-image: url(/img/banner/full/kwan.jpg);
	}
}

.Connections_2040 {
	.custom-color(#E49E58, @color-text-heading: #DC832B);
}

.Choices___Voices {
	.custom-color(#ABD14D, @color-text-heading: #394C69);
}

.Draft_2045_Long_Range_Plan {
	.custom-color(#ACD163, @color-text-heading: #384B66);
}

.Connections_2045_Long_Range_Plan_Update {
	.custom-color(#e46f2c, @color-background-content: #e5e6e6);

	#header {
		background-image: url(/img/banner/full/trafficnight.jpg);

		&:after {
			content: '';
		}
	}
}

.Regional_Indicators,
.Transportation.Regional_Indicators,
.Establishing_A_Modern_Multimodal_Transportation_System {
	.custom-color(#E37F1C, @color-text-heading-2: #c87019);
}

.Environmental,
.Managing_Growth_and_Protecting_the_Environment {
	.custom-color(#78a22f, @color-text-heading-2: #6c922a);
}

.Community,
.Creating_Livable_Communities {
	.custom-color(#4D85C5, @color-text-heading-2: #4D85C5);
}

.Economic,
.Building_The_Economy {
	.custom-color(lighten(#795200, 15%), @color-text-heading: #795200, @color-text-heading-2: #795200);
}

.Municipal_Funding_Opportunities {
	.custom-color(#939598);
}

.Commuter_Services {
	.custom-color(#D5BFB4, @color-text-heading: #835D49);

	#header {
		background-image: url(/img/banner/new/ntc.jpg);

		&:after {
			content: 'MCPC';
		}
	}
}

.RideECO {
	.custom-color(#b1e13f, @color-text-heading: #0a5377, @color-text-heading-2: #35b0ce);

	#header {
		background-image: url(/rideeco/img/jumbotron-bg.jpg);
	}
}

.Share_A_Ride {
	.custom-color(#009C9C);
}

.Mobility_Alternatives {
	.custom-color(#A6DCE0, @color-text-heading: #0078ae);
}

.Commute95 {
	.custom-color(#97ACD2, @color-text-heading: #24408E);

	#header {
		background-image: url(/img/banner/full/construction95.jpg);
	}
}

.About_Us {
	.custom-color(#cea77e, @color-text-heading: #8b6c4b);//#9DB459, #677836

	#header {
		background-image: url(/img/banner/full/philly1.jpg);
	}
}

.Public_Participation_Task_Force {
	.custom-color(#f7941d);

	#header {
		background-image: url(/img/banner/full/pptf-banner.png);
	}
}

.Land_Use_and_Environment {
	.custom-color(#E3CA63, @color-text-heading: #976826);//C9AF5E);

	#header {
		background-image: url(/img/banner/full/environment.jpg);
	}
}
.Transportation {
	.custom-color(#B0BEC5, @color-text-heading: #5D737E);

	#header {
		background-image: url(/img/banner/full/Transportation.jpg);
	}
}
.Safety {
	.custom-color(#F4A22D, @color-text-heading: #348791);
	
	#header {
		background-image: url(/img/banner/full/SafetyBanner.png);
	}
}
.Regional_Safety_Task_Force{
	.custom-color(#F4A22D, @color-text-heading: #348791);

	#header {
		background-image: url(/img/banner/full/RSTFWebBanner-02.png);
		background-size: contain;
		background-position: bottom center;
		background-attachment: scroll;
		margin-top: 50px;
		height: 25vw;
	}
}
.Burlington_County_Highway_Master_Plan{
	.custom-color(#6CA2AD, @color-text-heading-2: #666666);

	#header {
		background-image: url(/img/banner/full/BCHMP.png);
		background-position: bottom 10px;

		&:after {
			background: transparent url(/img/banner/full/BCHMP_overlay.png) no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: center bottom;
		}
	}
	.topBodyB h2{
	color: #FFFFFF;
	}
}
.PA_Turnpike_Interchange{
	.custom-color(rgba(66, 41, 109, 0.5), @color-text-heading: #42296d, @color-text-heading-2: #42296d);

	#header {
		background-image: url(/img/banner/full/PA_Turnpike_Banner.png);
	}
}


.Congestion_Management {
	.custom-color(#71AB43, @color-text-heading: #1E6E84, @color-background-content: #d7d9da, @color-background-nav: #00b1e9);
}

.Get_Involved {
	.custom-color(#CC9F61, @color-text-heading: #92692f);

	#header {
		background-image: url(/img/banner/full/Anniversary.jpg);
	}
}

.Planning_Assistance_Center {
	.custom-color(#9CCC65, @color-text-heading: hsla(88, 35%, 41%, 1));
	
	#header {
		background-image: url(/img/banner/full/mainstreet.jpg);
	}
}
.COVID_19_Resources {
	.custom-color(#006BA6, @color-text-heading: #5d737e);

	#header {
		background-image: url(/img/banner/full/covid19.jpg);

		&:after {
			background: transparent url(/img/banner/Overlay/covid19.png) no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: center bottom;
		}
	}
}

.Economic_Development {
	.custom-color(rgba(247, 148, 29, 0.8), @color-text-heading: rgb(102, 45, 145), @color-background-content: rgba(247, 148, 29, 0.2));
	
	#header {
		background-image: url(/img/banner/full/265_EconomicDevelopment.jpg);

		&:after {
			background: transparent url(/img/banner/Overlay/CEDS.png) no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: center bottom;
		}
	}
}
.Comprehensive_Economic_Development_Strategy__CEDS_ {
	#header {
		background-image: url(/img/banner/full/CEDS.png);
	}
}
.Regional_Community_and_Economic_Development_Forum{
	#header {
		background-image: url(/img/banner/full/265_RCDEF.jpg);
	}
}
.Immigration_in_Greater_Philadelphia{
	#header {
		background-image: url(/img/banner/full/265_Immigration.jpg);
	}
}
.Multimodal_Access_to_Tourist_Attractions{
	#header {
		background-image: url(/img/banner/full/265_Tourism.jpg);
	}
}
.Safe_Routes_to_Transit {
    .custom-color(#2A5B7C, @color-text-heading: #2A5B7C);//#699933);

    #navColumn .nav-menu > li {
        background: linear-gradient(to right,#dcedc9 0,#f7f7f7 65%);
        border-color: #dcedc9;
    }

    .nav-menu .visible > ul {
        background: linear-gradient(to right,#ebf5e0 0,#f7f7f7 65%);
    }

    #header {
        background-image: url('/img/banner/full/SRTT_banner.png');
        background-position: bottom 10px;

        &:after {
            background: transparent url('/img/banner/full/SRTT_logo.png') no-repeat;
            height: 100%;
            width: 100%;
            content: '';
            display: block;
            background-position: center bottom;
        }
    }
}
.Municipal_Bridge_Retro_Reimbursement_Program {
	#header {
		background-image: url(/img/banner/full/1632_Dowlin-BeforeAfter.jpg);
	}
}

.GIS_Data_and_Maps,
.GIS_Data,
.Interactive_Maps,
.Reference_Maps,
.Other_Resources {
	.custom-color(#A7DBEA, @color-text-heading: #2a455f);

	#header {
		background-image: url(/img/banner/full/gis.jpg);
	}
}

.Classic_Towns_of_Greater_Philadelphia {
	.custom-color(#ACC46D, @color-text-heading: #A44B33, @color-text-heading-2: #EEAD75);

	#header {
		background-image: url(/ClassicTowns/img/townscape.png);
	}
}

.Freight {
	.custom-color(#D07653, @color-text-heading: #b35631);

	#header {
		background-image: url(/img/banner/full/freight2.jpg);
	}
}

.Aviation {
	.custom-color(#A8C2D1, @color-text-heading: #6593AE);

	#header {
		background-image: url(/img/banner/full/pottstownairport.jpg);
	
		&:after {
	        content: 'MCPC';
	    }
	}
}

.\35 0_Years_of_Regional_Planning {
	.custom-color(@color-background-banner: @color-background-important, @color-text-heading: @color-text-heading-1);

	#header {
		background-image: url(/img/banner/new/waterworks.jpg);
		background-position: bottom;
	}
}

.Anniversary_Celebration {
	.custom-color(#E7CC75, @color-text-heading: @color-text-heading-1);

	#header {
		background-image: url(/img/banner/new/50thDinnerWebHeader_desktop.png);
	}
}
.Megaregion {
	.custom-color(#F8EB80, @color-text-heading: #5E8339, @color-text-heading-2: #769A4C);

	#header {
		background-image: url(/img/banner/full/megaregion.jpg);
	}
}

/*.Connect_With_Us {
	.custom-color(#54c5d0, @color-text-heading-2: #54c5d0, @color-background: #454f69, @color-background-content: #596688);

	#header {
		background-image: url(/ConnectWithUs/img/banner.png);
		background-position: 50% 50px;
	}

	body {
		background: #454f69;
	}

	#navColumn a {
		color: #fff;
		mix-blend-mode: normal;
	}

	#navColumn .nav-menu>li {
		border: none;
	}

	#navColumn>div>div {
		background: transparent;
		box-shadow: none;
	}
}*/

/* AR2016 Themes */

.Environmental_Planning,
.Regional_Trails_Program,
.Trails_Planning {
	.custom-color(#477a3a, @color-text-heading: #215990);

	#header {
		background-image: url(https://cms.dvrpc.org/sites/default/files/2022-05/Trails-3200_1.jpg);
		background-size: 1600px 400px;

		&:after {
	        content: 'Photo by Derek Lombardi';
	    }
	}
}

.Bicycle_Planning {
	.custom-color(#0071b3);

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate-04.jpg);
	}
}
.Bike_Friendly_Resurfacing_Program {
	.custom-color(#80953a);

	#header {
		background-image: url('/img/banner/full/BikeResurfacing_Banner.jpg')
	}
}

.Pedestrian_Planning {
	.custom-color(#5bbabe, @color-text-heading: #347c7f);

	#header {
		background-image: url(/img/banner/full/singing_fountain.jpg);
		
		&:after {
	        content: 'Photo by J. Fusco for VISIT PHILADELPHIA™';
	    }
	}
}
.Transit_Planning {
	.custom-color(#274193);

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate-07.jpg);
	}
}

.Transportation_Systems_Management_and_Operations {
	.custom-color(#DDB16E, @color-text-heading: #555);

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate-05.jpg);
	}
}

.Climate_Change_Resiliency {
	.custom-color(#e7f075, @color-text-heading: #306882);/*#D3FF66*/

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate-06.jpg);
	}
}



.Coordinated_Human_Services {
	.custom-color(#EFC65E, @color-text-heading: #6e6253);

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate2-01.jpg);
	}
}

.Equity_Through_Access {
	.custom-color(#9CCC65, @color-text-heading: hsla(88, 35%, 41%, 1));
	
	#header {
		background-image: url(/ETA/img/ETA_webPG_banner-01.png);
		background-position: bottoms 10px;
	}
}

.Food_System_Planning {
	.custom-color(#FFBC00, @color-text-heading: #cb0118);

	#header {
		background-image: url(/Food/MontCo/imgs/local_montco_banner.png);
	}
}

.Long_Range_Plan {
	.custom-color(#E06F19, @color-text-heading: #4a4c4d);

	#header {
		background-image: url('/LongRangePlan/img/LRPBanner-01.jpg');

		&:before {
			background: transparent url('/LongRangePlan/img/LRPBanner.png') no-repeat;
			height: 100%;
			background-position: 20% bottom;
			content:'';
			display: block;
		}

		&:after {
			content: 'NASA/GSFC/LaRC/JPL, MISR Team';
		}
	}
}
.Connections_2050 {
	.custom-color(#06688E, @color-text-heading: #06688E, @color-text-heading-2: #4a4c4d);

	#header {
		background-image: url('/LongRangePlan/img/1193_C2050_web-BANNER_BG-01.jpg');

		&:before{
			background: transparent url('/img/banner/Overlay/1193_C2050_web-BANNER_LOGO-02.png') no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: 'block';
			background-position: center bottom;
		}
		&:after{
			content: '';
		}
	}
}
.Futures_Group {
	.custom-color(#008D92, #84BBBF, @color-text-heading: #4a4c4d);

	#header {
		background-image: url('/LongRangePlan/img/LRPBanner-01.jpg');

		&:before {
			background: transparent url('/img/banner/Overlay/futures-group-logo.png') no-repeat;
			height: 100%;
			background-position: 50% bottom;
			content:'';
			display: block;
		}
		&:after{
			content: '';
		}
	}
}

.Freight {
	.custom-color(#166B5B);

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate-10.jpg);
	}
}

.Traffic_Incident_Management {
	.custom-color(#142F91);

	#header {
		background-image: url(/img/banner/full/ARPhotoUpdate2-02.jpg);
	}
}
.Community_Revitalization {
	.custom-color(#167a7a, @color-background-banner: #1d6260, @color-background-nav: #b2cccb);

	#header {
		background-image: url(/communityrevitalization/img/banner.jpg);
	}
	#header:after {
		content:'Photo by Bob Kelly, West Chester';
	}
}
.Classic_Towns_of_Greater_Philadelphia {
	.custom-color(#AA5544, @color-text-heading: #AA5544, @color-text-heading-2: #EFAA67, @color-background-nav: #F7D5B3 );

	#header {
		background-image: url(/img/banner/full//classic_towns_sunset.png);
	}
	#header:after {
		content: '';
	}
}
.Breaking_Ground_Conferences {
	.custom-color(#e27126, @color-text-heading: #834d2f, @color-text-heading-2: #834d2f);

	#header {
		background-image: url(/breakingground/img/banner.jpg);
	}
}

.Changing_Lanes {
	.custom-color(#ffa300, @color-background-nav: #B0F0FF, @color-text-heading: #084f6c);

	#header {
		background-image: url('/ChangingLanes/img/banner.png');
		background-position: bottom 10px;
	}
}

.Vibrant_Ports {
	.custom-color(#fbb040, @color-background-nav: #3299c6, @color-text-heading: #252570);

	#header {
		background-image: url('/VibrantPorts/img/PortsBannerPhoto.jpg');
		background-position: bottom 10px;
	}
}

.Transportation_and_Community_Development_Initiative__TCDI_ {
	.custom-color(#083449, @color-background-nav: lighten(#e2d23a, 50%, "relative"));

	#header {
		background-image: url('/TCDI/img/TCDIBanner-01.png');
		background-position: bottom 10px;

		&:after {
			background: transparent url('/TCDI/img/TCDIbanner.png') no-repeat;
			height: 260px;
			width: 100%;
			content: '';
			display: block;
			bottom:20px;
			background-position: center bottom;
		}
	}
}

.Camden_Health_Element {
	.custom-color(#008F89, @color-text-heading-2: #008f89);

	#header {
		background-image: url('/img/banner/full/1997_CHE_banner.png');

		&:after {
			background: transparent url('/img/banner/full/1997_CHE_logo.png') no-repeat;
			height: 100%;
			width: 87%;
			content: '';
			display: block;
			background-position: left bottom;
		}
	}

	@media(min-width: 1300px) {
		#header {
			&:after {
				width: 74.5%;
			}
		}
	}

}

.Reviving_Vine__Improving_Multimodal_Connections_on_Vine_Street {
	.custom-color(#b5bf35, @color-background-nav: #c6c6c6, @color-text-heading-2: #484e50);

	#header {
		background-image: url('/img/banner/new/10thAndVine.jpg');
	}
}

.Frankford_Avenue_Multimodal_Study {
	.custom-color(rgb(114,197,147), @color-text-heading: rgb(39,112,122), @color-text-heading-2: rgb(39,112,122) );
}

.Montgomery_County_Local_Food_Promotion_Study {
	.custom-color(#466E34);

	#header {
		background-image: url('/Food/MontCo/imgs/1586_35_WillowCreekFarmFlowers.jpg');
		background-position: bottom 10px;

		&:after {
			background: transparent url('/Food/MontCo/imgs/1586_CoverHeader-02.png') no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: 15% bottom;
		}
	}
}

.Municipal_Water_Quality_Actions {
	.custom-color(#4161A5);

	#header {
		background-image: url('/WaterQuality/MunicipalActions/img/webpage_banner.jpg');
		background-position: bottom 10px;

		&:after {
			background: transparent url('/WaterQuality/MunicipalActions/img/webpage_logo.png') no-repeat;
			height: 243px;
			width: 100%;
			content: '';
			display: block;
			bottom:30px;
			background-position: center bottom;
		}
	}
}

.DRWI_Data_and_GIS_Work_Group {
	.custom-color(#004599);

	#header {
		background-image: url('/img/banner/new/DRWIWebBanner-01.jpg');
	}
}

.Congestion_Mitigation_and_Air_Quality__CMAQ_ {
	.custom-color(#0b388a);

	#header {
		background-image: url('/img/banner/full/CMAQ.png');

		&:after {
			background: transparent url('/img/banner/CMAQ_overlay.png') no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: center bottom;
		}
	}
}

.RSLPP, .RSLPP-2015 {
	.custom-color(#1185cc, @color-background-nav: #eddd8e, @color-text-heading-2: #2e577d);

	#header {
		background-image: url('/img/banner/full/1890_WebBanner-02.png');

		&:after {
			background: transparent url('/img/banner/full/1890_WebBanner-03.png') no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: center bottom;
		}
	}
}

.News{
	.custom-color(#03688D, @color-background-nav: #c1deee, @color-text-heading-2: #03688d);

	#header {
		background-image: url('/img/banner/full/newsroom_banner.png');

		&:after{
			background: transparent url('/img/banner/Overlay/newsroom_overlay.png') no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: center bottom;
		}
	}
}
.Community_Impacts_of_Multifamily_Development{
	#header {
		background-image: url('/img/banner/full/SmartGrowthApartmentsBanner.jpg');
	}
}
.Urban_Waterfront_Action_Group {
	.custom-color(rgb(166,219,215), @color-text-heading: rgb(46,63,81));

	#header {
		background-image: url('/img/banner/full/UWAG_banner.jpg');
	}
}
.Downingtown_Area_Transportation_Study {
	#header {
		background-image: url('/Mobility/Downingtown/img/Downingtown_banner.jpg');
		background-position: left center;
	}
}
.Ben_Franklin_Bridge_Eastbound_Access {
	#header {
		background-image: url('/img/banner/full/ben_franklin_bridge.png');
		background-position: center top;

		&:after {
			content: 'Photo Credit: Amy Verbofsky';
		}
	}
}
.Water_Table{
	.custom-color(#f7941d, @color-background-nav: #79d2f5, @color-text-heading: #3e79bb, @color-text-heading-2: #3e79bb);

	#header {
		background-image: url('/img/banner/full/WATER-table_BannerImage-01.jpg');

		&:after{
			background: transparent url('/img/banner/Overlay/WATER-table_BannerLogo_B-02.png') no-repeat;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-position: center bottom;
		}
	}
}
.Transportations_Operations_Task_Force {
	.custom-color(#f78131, @color-text-heading: #213f7c);
}
.Public_Participation_Task_Force {
	.custom-color(#005772, @color-background-nav: #006e91);

	#header {
		background-image: url('/img/banner/full/7794_PPTFWebsiteBanner-02.png');
		background-size: contain;
		background-position: bottom center;
		background-attachment: scroll;
		margin-top: 50px;
		height: 25vw;
	}
}
.Regional_Technical_Committee {
	.custom-color(#005772, @color-background-nav: #006e91);

	#header {
		background-image: url('/img/banner/full/7795_RTCWebsiteBanner-01.png');
		background-size: contain;
		background-position: bottom center;
		background-attachment: scroll;
		margin-top: 50px;
		height: 25vw;
	}
}