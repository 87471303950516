
.container() {
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	.clearfix();

	@media (min-width: @screen-sm-min) {
		width: 750px;
	}

	@media (min-width: @screen-md-min) {
		width: 794px;
	}

	@media (min-width: @screen-lg-min) {
		width: 960px;
	}
}

.row {
	margin-left: -15px;
	margin-right: -15px;
	.clearfix();
}

.make-xs-column(@columns; @gutter: 30px) {
  position: relative;
  min-height: 1px;
  padding-left:  (@gutter / 2);
  padding-right: (@gutter / 2);
  float: left;
  width: percentage((@columns / 12));
}

.make-sm-column(@columns; @gutter: 30px) {
  position: relative;
  min-height: 1px;
  padding-left:  (@gutter / 2);
  padding-right: (@gutter / 2);

  @media (min-width: @screen-sm-min) {
    float: left;
    width: percentage((@columns / 12));
  }
}

.col-xs-1 {
	.make-xs-column(1);
}

.col-xs-2 {
	.make-xs-column(2);
}

.col-xs-3 {
	.make-xs-column(3);
}

.col-xs-4 {
	.make-xs-column(4);
}

.col-xs-5 {
	.make-xs-column(5);
}

.col-xs-6 {
	.make-xs-column(6);
}

.col-xs-7 {
	.make-xs-column(7);
}

.col-xs-8 {
	.make-xs-column(8);
}

.col-xs-9 {
	.make-xs-column(9);
}

.col-xs-10 {
	.make-xs-column(10);
}

.col-xs-11 {
	.make-xs-column(11);
}

.col-xs-12 {
	.make-xs-column(12);
}

.col-sm-1 {
	.make-sm-column(1);
}

.col-sm-2 {
	.make-sm-column(2);
}

.col-sm-3 {
	.make-sm-column(3);
}

.col-sm-4 {
	.make-sm-column(4);
}

.col-sm-5 {
	.make-sm-column(5);
}

.col-sm-6 {
	.make-sm-column(6);
}

.col-sm-7 {
	.make-sm-column(7);
}

.col-sm-8 {
	.make-sm-column(8);
}

.col-sm-9 {
	.make-sm-column(9);
}

.col-sm-10 {
	.make-sm-column(10);
}

.col-sm-11 {
	.make-sm-column(11);
}

.col-sm-12 {
	.make-sm-column(12);
}

.make-sm-column-push(@columns) {
  @media (min-width: @screen-sm-min) {
    left: percentage((@columns / 12));
  }
}

.col-sm-push-1 {
	.make-sm-column-push(1);
}

.col-sm-push-2 {
	.make-sm-column-push(2);
}

.col-sm-push-3 {
	.make-sm-column-push(3);
}

.col-sm-push-4 {
	.make-sm-column-push(4);
}

.col-sm-push-5 {
	.make-sm-column-push(5);
}

.col-sm-push-6 {
	.make-sm-column-push(6);
}

.col-sm-push-7 {
	.make-sm-column-push(7);
}

.col-sm-push-8 {
	.make-sm-column-push(8);
}

.col-sm-push-9 {
	.make-sm-column-push(9);
}

.col-sm-push-10 {
	.make-sm-column-push(10);
}

.col-sm-push-11 {
	.make-sm-column-push(11);
}

.make-sm-column-pull(@columns) {
  @media (min-width: @screen-sm-min) {
    right: percentage((@columns / 12));
  }
}

.col-sm-pull-1 {
	.make-sm-column-pull(1);
}

.col-sm-pull-2 {
	.make-sm-column-pull(2);
}

.col-sm-pull-3 {
	.make-sm-column-pull(3);
}

.col-sm-pull-4 {
	.make-sm-column-pull(4);
}

.col-sm-pull-5 {
	.make-sm-column-pull(5);
}

.col-sm-pull-6 {
	.make-sm-column-pull(6);
}

.col-sm-pull-7 {
	.make-sm-column-pull(7);
}

.col-sm-pull-8 {
	.make-sm-column-pull(8);
}

.col-sm-pull-9 {
	.make-sm-column-pull(9);
}

.col-sm-pull-10 {
	.make-sm-column-pull(10);
}

.col-sm-pull-11 {
	.make-sm-column-pull(11);
}
