html,
body {
  min-height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

.clearfix {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

.box-shadow(@box-shadow: 0 0 4px rgba(0,0,0,0.175)) {
  -webkit-box-shadow: @box-shadow;
  -moz-box-shadow: @box-shadow;
  box-shadow: @box-shadow;
}

.best-contrast(@color, @color-dark: @color-dark, @color-light: #fff) {
  @best-contrast: contrast(@color, @color-dark, @color-light, 24%);
}

.card {
  background-color: @color-background-highlight;
  border-radius: 2px;
  border: none;
  .box-shadow(
    0 2px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 5px 0 rgba(0, 0, 0, 0.06) ;
  );
  padding: 18px;
  overflow: hidden;
  margin-bottom: 10px;

  > h2:first-child {
    margin: -18px -18px 0;
    padding: 9px 18px;
    background-color: @color-text-heading-2;
    color: contrast(@color-text-heading-2, @color-dark);
  }

  > h3:first-child {
    margin: -18px -18px 0;
    padding: 9px 18px;
    background-color: @color-text-heading-3;
    color: contrast(@color-text-heading-3, @color-dark);
  }

  > footer,
  > .footer {
    margin: 0 -18px -18px;
    padding: 4.5px 18px;
    background-color: @color-background;
    text-align: right;
    border-top: 1px solid #eee;
    font-weight: bold;
  }

  .list-group {
    margin-left: -18px;
    margin-right: -18px;
    .box-shadow(none);

    > .list-group-item,
    > .list-group-heading {
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 0;
    }
  }

  *:first-child + .list-group {
    margin-top: 0;
  }

  .list-group:last-child {
    margin-bottom: -18px;
  }
}

.list-group {
  > h2:first-child {
    margin: 0;
    padding: 9px 18px;
    background-color: @color-text-heading-2;
    color: white;
  }

  > h3:first-child {
    margin: 0;
    padding: 9px 18px;
    background-color: @color-text-heading-3;
    color: white;
  }
}

.media {
  .clearfix();
}

.media-right {
  float: right;
}

.media-left,
.media-left a,
.media-right,
.media-right a {
  .no-underline();

  img {
    max-width: initial;
  }
}

.media-heading,
.media-body h3,
.media-body h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  float: right;
  margin-top: 10px;
  width: 100%;
}

.list-inline > li {
  display: table-cell;
  width: 1%;
}

.media.list-group-item {
  margin-top: 0;
}

.link-color(@color: @color-text-body, @opacity: 31%) {
  a {
    color: inherit;
    /*    text-decoration: none;
    text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff;
    box-shadow: 0 .08em 0 fade(@color, @opacity);
    mix-blend-mode: multiply;
  }

  a when (lightness(@color) > 50%) {
    text-shadow: 1px 1px 0 #000, -1px 1px 0 #000, 2px 0 0 #000, -2px 0 0 #000;
    box-shadow: 0 .09em 0 rgba(255,255,255,.31);
    mix-blend-mode: screen;*/
  }
}
.link-color();

.block {
  display: block;
}

.no-underline {
  box-shadow: none !important;
  text-shadow: none !important;
}

.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.placeholder(@color: @color-text-heading-4) {
  :-moz-placeholder {
    color: @color;
  }
  ::-webkit-input-placeholder {
    color: @color;
  }
}

.user-select(@select) {
  -webkit-user-select: @select;
  -moz-user-select: @select;
  -ms-user-select: @select;
  user-select: @select;
}

.opacity(@opacity: 100) {
  opacity: @opacity / 100;
  filter: e(%("alpha(opacity=%d)", @opacity));
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Single side border-radius

.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

p,
ul,
ol,
form {
  margin: 1em 0;
}

hr {
  margin: 1em 0 2em;
  border: 0;
  border-top: 1px solid @color-border;
  height: 0;
  width: 100%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  clear: both;
}

figure {
  border: 1px solid @color-border;
  padding: 5px;
  float: right;
  margin: 0 0 10px 10px;
  border-radius: 3px;

  figcaption {
    font-style: italic;
    padding: 5px 5px 0;
  }
}

img {
  max-width: 100%;
}

a[rel="external"]::after,
a[rel="noopener"]::after,
a[href^="mailto:"]::after,
a[href^="tel:"]::after {
  display: inline;
  margin-left: 3px;
  content: url(/img/external-link.svg);
  color: inherit;
}

a.gitem::after,
a.no-underline::after,
a.btn::after {
  display: none;
}

a[href^="mailto:"]::after {
  content: url(/img/mail.svg);
}

a[href^="tel:"]::after {
  content: url(/img/phone.svg);
}

ul[data-pubs],
ul[data-pubs-type],
.row.active:empty,
.card:empty,
#footer > .row:empty {
  background: url("/img/Ellipsis-3s-100px.svg") center no-repeat;
  min-height: 50px;
  min-width: 50px;
}

.icon {
  position: relative;
  top: 1px;
  display: inline-block;
  background: transparent url("/img/homepage/sprites.svg") no-repeat;
  background-size: 167px 18px;
  height: 18px;
  width: 18px;
  content: " ";
}

.icon-search {
  background-position: -29px 0;
  padding: 0;
}

.icon-translate {
  background-position: 0 0;
  padding: 0;
}

.icon-news {
  background-position: -1px 0;
  width: 27px;
}
/*
.icon-index {
    background-position: -35px 0;
}

.icon-rss {
    background-position: -89px 0;
}
*/
.icon-fb {
  background-position: -128px 0;
}

.icon-twitter {
  background-position: -92px 0;
}

.icon-linkedin {
  background-position: -147px 0;
}

.icon-instagram {
  background-position: -110px 0;
}
/*
.icon-flickr {
    background-position: -161px 0;
    width: 19px;
}

.icon-home {
    background-position: -16px 0;
}
*/
.icon-youtube {
  background-position: -66px 0;
  width: 26px;
}

#google_translate_element {
  margin-left: 5px;
}

.goog-te-gadget-simple {
  padding: 0 !important;
  line-height: 1;
  margin: 0 auto;
  vertical-align: top;
}

.goog-te-gadget-simple::after {
  font-family: @font-heading;
  content: "\00a0Translate";
  display: inline-block;
  color: @color-medium;
  line-height: 18px;
  font-size: 18px;
}

.goog-te-gadget-icon {
  .icon();
  left: 3px;
  background-image: url("/img/homepage/sprites.svg") !important;
  background-position: -48px 0 !important;
  margin: 0 !important;
  width: 18px !important;
  height: 18px !important;
  vertical-align: top !important;
}

.goog-te-gadget-simple > span {
  display: none;
}

table {
  width: 100%;
  margin: 1em 0;
}

.table-hover {
  tbody tr:hover {
    background: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.table-striped {
  tbody tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}

caption {
  .h3();
  margin: 0 0 0 5px;
  text-align: left;
}

th,
td {
  padding: 5px;
  text-align: left;
}

td {
  border-bottom: 1px solid @color-border;
}

tbody {
  border-bottom: 2px solid @color-border;
  border-top: 2px solid @color-border;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

.btn {
  .no-underline();
}

#mainContent .btn .sm {
  color: @color-light;
}

#Tabs {
  .card();
}

#Tabs_header,
#Tabs_body {
  font-family: inherit;
  font-size: 1em;
  border: none;
  padding: 0;
  background-color: transparent;
}

#Tabs_header {
  .h4();

  &,
  & * {
    background: none;
    height: auto;
  }

  .ajax__tab_active {
    .h3();
    border-bottom: 1px solid @color-border;
  }
}

summary{
  background-color: #eee; /*replace w/colors of your choice*/
  font-size: 1.1em;
  width: 100%;
  padding: 10px;
  margin-bottom: 3px;
  text-align: left;
  transition: 0.4s;
  cursor: pointer;
  outline: none;
  border-left: medium solid @color-medium; /*replace w/colors of your choice*/
  border-radius: 3px;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.3);
}
summary:hover{
  box-shadow: 2px 4px 6px rgba(0,0,0,0.3);
}
summary::-webkit-details-marker {
  display: none;
}
summary:after{
  content: '\02795';
  float: right;
}
summary:hover{
  background-color: #ccc; /*replace w/colors of your choice*/
}
details[open] summary:after{
  content: '\02796';
}
details[open] {
  background: #f7f7f7;
}