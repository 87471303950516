@media print {
	#rightHandWrap:before,
	#rightHandWrap:after,
	#topCurves,
	#navColumn,
	#widgetColumn,
	#header,
	#topBodyCurves,
	#subMenuItem,
	#footerWrap,
	#footer2Wrap {
		display: none !important;
	}

	body,
	#leftHandWrap,
	#rightHandWrap,
	#bodyWrap,
	#mainColumn,
	#contactWrap,
	#contact,
	#contact .row>div,
	#mainContent {
		background: none transparent !important;
		position: static !important;
		float: none !important;
		width: 100% !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	* {
		color: #000 !important;
	}

	h1 {
		margin-top: 0;
	}

	a:after {
		content: ' (' attr(href) ')';
		color: #666 !important;
		font-size: 0.9em;
		text-transform: none;
	}

	a[href^='/']:after {
		content: ' (www.dvrpc.org' attr(href) ')';
	}
}